window.csrf = $('meta[name="csrf-token"]').attr('content');

if (csrf === undefined) {
    console.log("csrf not found");
} else {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': window.csrf
        }
    });

    axios.defaults.headers.common = {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': window.csrf
    };
}