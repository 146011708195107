require('./bootstrap');

document.addEventListener("DOMContentLoaded",function(event) {
	let description_textarea = document.getElementById('description');

	if(description_textarea){
		const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
		const controls_height = 109;
		const status_height = 28;
		const buttons_height = 38;
		const foo = 14;
		const rect_top = 200;//rect.top;
		let rect = description_textarea.getBoundingClientRect();
		let ckeditor_height = Math.floor(vh - rect_top - controls_height - status_height - buttons_height + foo);
		// console.log(JSON.stringify({
		// 	vh, rect_top, controls_height,
		// 	status_height, buttons_height, foo,
		// 	ckeditor_height
		// }, null, 2));

		let config = {};
		config.extraPlugins = 'uploadimage';
		config.uploadUrl = '/api/uploadimage';
		config.filebrowserUploadMethod = 'form';
		config.filebrowserBrowseUrl = '/fileman/';
		config.filebrowserUploadUrl = '/uploader/upload.php';

		config.removePlugins = 'exportpdf';

		config.extraPlugins = 'autogrow';
		config.autoGrow_onStartup = true;
		config.autoGrow_minHeight = ckeditor_height;
		// config.autoGrow_maxHeight = 600;

		let editor = CKEDITOR.replace( 'description', config );
		// editor.resize( '100%', '100%', true );
	}
});

$(document).ready(function(){
	$('#parent_id').select2({
		theme: 'bootstrap4',
	});
}).on('keydown', 'select#parent_id', function(event){
	if(
		event.ctrlKey &&
		event.shiftKey &&
		(
			event.key.toLowerCase() == 'f' ||
			event.key.toLowerCase() == 'ф'
		)
	){
		event.preventDefault();
		// console.log(event.altKey, event.ctrlKey, event.char, event.charCode, event.metaKey, event.key);

		let select = this;
		let $select = $(select);

		let $autocomplete = $select.parent().find('.autocomplete');
		$autocomplete.addClass('active');
		$autocomplete.val('');
		$autocomplete.focus();
	}
}).on('change', '.autocomplete', function(event){
	let $autocomplete = $(this);
	let value = this.value;
	$('select#parent_id option').each(function(i,e){
		if(value == e.value){
			$(e).prop('selected', true); 
		}
	});
	$autocomplete.removeClass('active');
	$('select#parent_id').focus();
}).on('change', '.language-form select', function(event){
	let locale = $(this).val();

	$.ajax({
		url: '/language', method: 'POST', data: {locale}, success: function(response){
			// $.fancybox.open(response);
			window.location.reload(true);
		}
	});
}).on('submit', '.search-form', function(event){
	event.preventDefault();

	let search = ($('[type="search"]').val() || '').trim();
	let data = {search};
	let params = new URLSearchParams(data).toString();
	let goto = `/search?${params}`;
	window.location.href = goto;
	// $.ajax({
		// url: '/search', method: 'POST', data: {search}, success: function(response){
			// $.fancybox.open(response);
		// }
	// });
}).on('click', '.description-container img', function(event){
	// let rect = this.getBoundingClientRect();
	$.fancybox.open(`<img src="${this.src}">`);
}).on('click', '.expander', function(event){
	event.preventDefault();
	$(this).parent().toggleClass('expand');
}).on('click', '[data-remove_btn]', function(event){
	if(!confirm('Вы уверены?')){
		event.preventDefault();
	}
});
